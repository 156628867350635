// SolutionsData.js

import projectmanagement from "../assets/images/stock/projectmanagement.jpg";
import processmapping from "../assets/images/stock/mapping.jpg";
import softwaredevelopment from "../assets/images/stock/appdevelopment.svg";
import QA from "../assets/images/stock/QA.jpg";

export const SolutionsData = [
  {
    id: 1,
    title: "Project Management",
    description:
      "Turn your vision into reality. Our expert project management ensures on-time delivery, cost-efficiency, and flawless execution, making your projects a resounding success.",
    imageUrl: projectmanagement,
  },
  {
    id: 2,
    title: "Business Process Mapping",
    description:
      "Uncover efficiency. Our process mapping reveals hidden opportunities, boosts productivity, and guides your path to streamlined success.",
    imageUrl: processmapping,
  },
 
  {
    id: 3,
    title: "Custom Software Development",
    description:
      "Empower innovation. Our software development solutions unlock new possibilities, enabling your business to thrive and lead with cutting-edge technology.",
    imageUrl: softwaredevelopment,
  },
  {
    id: 4,
    title: "Quality Assurance",
    description:
      "Deliver perfection. Our quality assurance processes ensure flawless products that exceed expectations, delighting your customers every time.",
    imageUrl: QA,
  },
];
