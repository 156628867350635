
<template>
    <div class="menu-section">
        <div class="logo">
            <a href="/"><img src="@/assets/logo.png" alt="Logo" /></a>
        </div>

        <nav class="navbar">

            <div class="dropdown">
                <router-link to="/">Home</router-link>
            </div>

            <div class="dropdown">
                <router-link to="/products">Products</router-link>
                <div class="dropdown-content">
                    <ul class="product-menu">
                        <li v-for="product in productsData" :key="product.id"
                            @click="this.$router.push(`/products/${product.id}`)">
                            <h4>{{ product.menu_name }}</h4>
                            <span>{{ product.menu_description }}</span>
                            <p>{{ product.title }}</p>
                            <p>{{ product.description }}</p>    
                            <p>{{ product.Modules }}</p>

                        </li>
                    </ul>   
                </div>


            </div>
            <div class="dropdown">
                <router-link to="/developers">Developers</router-link>
            </div>
          
            <div class="dropdown">
                <router-link to="/contact">Support</router-link>
            </div>



        </nav>
        <nav>

            <div class="dropdwon">
                <router-link to="/about">company</router-link>
            </div>
            <!-- <div class="dropdown">
        <router-link to="/blog">Blog</router-link>
      </div> -->
            <div class="dropdown">
                <!-- <router-link to="/contact">Contact</router-link> -->
                <router-link to="/contact">Get in Touch</router-link>

            </div>
        </nav>

    </div>
</template>

<script>
import { SolutionsData } from "@/utils/SolutionsData";
import { productsData } from "@/utils/ProductData";

export default {
    setup() {
        return { SolutionsData, productsData };
    },
    methods: {
        navigateDemo() {
            this.$router.push("/demo");
        }
    }
};
</script>

<style lang="scss" scoped></style>
